import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Footer from "../components/footer"
import ServiceItem from "../components/serviceItem"
import { FaPencilAlt, FaCogs, FaFlask, FaCheck } from "react-icons/fa"

const imageQuery = graphql`
  query {
    advisory_board: file(relativePath: { eq: "advisory_boards.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    reunion_con_expertos: file(
      relativePath: { eq: "reunion_con_expertos.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    estrategia_trabajo_en_equipo: file(
      relativePath: { eq: "estrategia_trabajo_en_equipo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    diseno_anteproyecto: file(relativePath: { eq: "diseno_anteproyecto.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    formacion_medica_continuada: file(
      relativePath: { eq: "formacion_medica_continuada.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    proyectos_delphi: file(relativePath: { eq: "proyectos_delphi.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ServiceList = [
  {
    icon: <FaPencilAlt />,
    title: "Diseño",
    description:
      "Gracias a la experiencia del equipo de Got It, ante cada nuevo proyecto podemos proponer diferentes alternativas metodológicas, diseñando propuestas de calidad que se ajusten a las necesidades del cliente.",
  },
  {
    icon: <FaCogs />,
    title: "Desarrollo",
    description:
      "Podemos desarrollar el proyecto definido, ya sea ejecutándolo directamente o apoyando al cliente en la dirección y ejecución propia del proyecto (selección de proveedores, elaboración de memoria del proyecto, seguimiento, monitorización, etc.).",
  },
  {
    icon: <FaFlask />,
    title: "Análisis de resultados",
    description:
      "En fases intermedias del proyecto (y una vez finalizado), analizamos los resultados y posibles puntos críticos del proceso para adelantarnos a los problemas que puedan ir surgiendo y garantizar, así, el éxito del proyecto. En proyectos de larga extensión, realizamos reuniones periódicas con el cliente y elaboramos cuadros de mando (si aplica) e informes de estado del proyecto.",
  },
  {
    icon: <FaCheck />,
    title: "Finalización",
    description:
      "Al cierre de un proyecto, elaboramos un informe global de resultados y lo analizamos en reunión presencial con el cliente. En este informe incluimos, fruto de nuestra experiencia en el sector, recomendaciones específicas que consideramos pueden contribuir a la consecución de los objetivos de nuestro cliente.",
  },
]

const ServicesPage = () => {
  const {
    advisory_board,
    reunion_con_expertos,
    estrategia_trabajo_en_equipo,
    diseno_anteproyecto,
    formacion_medica_continuada,
    proyectos_delphi,
  } = useStaticQuery(imageQuery)

  return (
    <Layout title="Qué hacemos">
      <div className="slider-wrapper">
        <Slider title="Qué hacemos" />
      </div>

      <div className="rn-columns-area pt--80 bg_color--5">
        <div className="container">
          <ServiceItem
            thumbnail={advisory_board}
            title="Diseño y dirección de Advisory Boards"
          >
            <h4>Presenciales o a distancia</h4>
            <p className="description">
              Que faciliten la construcción o la evaluación de los planes del
              Departamento de Marketing, Departamento Médico, Relaciones
              Institucionales (RRII) y Market Access. Contamos con una amplia
              experiencia en diferentes especialidades (oncología, hematología,
              urología, cardiología, nefrología, trasplantes, neumología,
              ginecología, reumatología, etc.).
            </p>
          </ServiceItem>

          <ServiceItem
            thumbnail={reunion_con_expertos}
            title="Diseño y dirección de reuniones de expertos"
            thumbnailRight={true}
          >
            <p>
              Contamos con una amplia experiencia en diferentes especialidades
              (oncología, hematología, urología, cardiología, nefrología,
              trasplantes...) y también con grupos de pacientes.
            </p>
          </ServiceItem>

          <ServiceItem thumbnail={proyectos_delphi} title="Proyectos Delphi">
            <p>
              Que promuevan el consenso entre expertos sobre pronósticos
              relativos a la especialidad / tratamientos así como consensos
              relativos a recomendaciones de práctica clínica.
            </p>
          </ServiceItem>

          <ServiceItem
            thumbnail={estrategia_trabajo_en_equipo}
            title="Diseño de estrategias de trabajo conjunto entre equipos"
            thumbnailRight={true}
          >
            <p>Que faciliten:</p>
            <ul>
              <li>El establecimiento de un análisis de situación</li>
              <li>
                La construcción de planes estratégicos y de planes de acción.
              </li>
            </ul>
            <p>
              Promoviendo la sinergia del conocimiento del equipo en pro de un
              objetivo común y propiciando una mayor corresponsabilización.
            </p>
            <p>
              Tenemos experiencia en el trabajo de este tipo de proyectos en
              diferentes ámbitos:
            </p>
            <ul>
              <li>
                En y entre departamentos de la industria farmacéutica con el
                objetivo de analizar y establecer un plan común.
              </li>
              <li>En servicios médicos.</li>
              <li>En sociedades científicas.</li>
            </ul>
          </ServiceItem>

          <ServiceItem
            thumbnail={diseno_anteproyecto}
            title="Diseño de anteproyectos"
          >
            <ul>
              <li>Derivados del consejo obtenido en advisory boards.</li>
              <li>Necesarios para el desarrollo de la estrategia.</li>
            </ul>
            <p>
              La amplia experiencia del equipo de Got It nos permite identificar
              y proponer proyectos e iniciativas que apoyen la estrategia de
              nuestro cliente. Podemos:
            </p>
            <ul>
              <li>
                Diseñar anteproyectos que faciliten la toma de decisiones.
              </li>
              <li>
                Apoyar a nuestro cliente en la dirección/ejecución del proyecto
                (selección de proveedores, elaboración de memoria del proyecto,
                seguimiento, monitorización).
              </li>
            </ul>
          </ServiceItem>

          <ServiceItem
            thumbnail={formacion_medica_continuada}
            title="Diseño y desarrollo de iniciativas de formación médica continuada"
            thumbnailRight={true}
          >
            <p>Con el objetivo de:</p>
            <ul>
              <li>
                Facilitar una mejora del conocimiento de una patología (o una
                mayor sensibilidad por la misma).
              </li>
              <li>
                Promover la adopción de cambios en el manejo de la patología
                (fármacos, instrumentos y métodos).
              </li>
              <li>
                Conocer los métodos de trabajo de centros más avanzados en
                determinadas patologías/técnicas (estancias en Centros de
                Referencia).
              </li>
            </ul>
          </ServiceItem>
        </div>
      </div>

      <div className="rn-columns-area pt--120 bg_color--1">
        <div className="container">
          <div className="section-title text-center mb--80">
            <h3>Cómo hacemos</h3>
          </div>

          <div className="pb--120">
            <div className="row">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="service service__style--2 mb--30">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">
                        {i + 1}. {val.title}
                      </h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default ServicesPage
