import React from "react"
import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"

const Thumbnail = ({ thumbnail, thumbnailRight }) => (
  <div className="col-lg-4 col-md-6 col-12 mb--100">
    <div className="single-column" style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="thumbnail" style={{ width: "80%" }}>
          <Img fluid={thumbnail.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  </div>
)

const ServiceItem = ({ thumbnail, title, thumbnailRight, children }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 991px)" })

  return (
    <div className="mtb--120">
      <div className="row">
        {(!thumbnailRight || isSmallScreen) && (
          <Thumbnail thumbnail={thumbnail} />
        )}
        <div className="col-lg-8 col-md-6 col-12 mb--100">
          <div className="single-column service-item">
            <h3>{title}</h3>
            {children}
          </div>
        </div>
        {thumbnailRight && !isSmallScreen && (
          <Thumbnail thumbnail={thumbnail} />
        )}
      </div>
    </div>
  )
}

export default ServiceItem
